<template>
  <div class="zone">
    <TJDetailTitle :titleData="'基本信息'" />
    <TJDetailMenu :dataList="dataList" />

    <TJDetailTitle :titleData="'定标吸光度'" />

    <div class="sheet">
      <el-table :data="tableData" stripe style="width: 100% height:100%">
        <el-table-column label="序号" height="30" align="center" width="100">
          <template slot-scope="scope">
            {{ scope.$index + 1 }}
          </template>
        </el-table-column>
        <el-table-column
          prop="calibration_abs_tar_data"
          label="靶值"
          height="30"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="line1_abs_data"
          label="吸光度1"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="line2_abs_data"
          label="吸光度2"
          align="center"
        ></el-table-column>
      </el-table>
    </div>

    <TJDetailTitle :titleData="'定标曲线'" />
    <div :class="detailData.ai_result == '正常' ? 'melee zc' : 'melee yc'">
      AI判断：{{ detailData.ai_result }}
    </div>
    <div id="main"></div>
  </div>
</template>

<script>
import apiDetail from '@/api/searchDetail.js'
import TJDetailTitle from '@/components/TJDetailTitle'
import TJDetailMenu from '@/components/TJDetailMenu'

export default {
  name: 'CalibrationDetail',
  components: { TJDetailTitle, TJDetailMenu },
  data() {
    return {
      dataList: [
        {
          name: '医院',
          value: '',
          width: '25%',
        },
        {
          name: '机号',
          value: '',
          width: '25%',
        },
        {
          name: '仪器型号',
          value: '',
          width: '25%',
        },
        {
          name: '检测项目',
          value: '',
          width: '25%',
          rightBorder: false,
        },
        {
          name: '试剂批号',
          value: '',
          width: '25%',
          bottomBorder: false,
        },
        {
          name: '仪器位置',
          value: '',
          width: '25%',
          bottomBorder: false,
        },
        {
          name: '创建时间',
          value: '',
          width: '25%',
          bottomBorder: false,
        },
      ],
      myChart: '',
      tableData: [],
      tableDataDity: [],
      detailData: {},
      seriesData: [],
      seriesDatatwo: [],
      seriesDataX: [],
    }
  },
  mounted() {
    // this.echartsShow()
    this.detailId = this.$route.query.id
    this.gitcalibrationDetail()
    window.addEventListener('resize', this.handlerResize);
  },
  destroyed() {
    window.removeEventListener('resize', this.handlerResize);
  },
  methods: {
    // 刷新组件
    handlerResize(){
      this.myChart.resize()
    },
    gitcalibrationDetail() {
      apiDetail
        .calibrationDetail({ calibration_id: this.detailId })
        .then((res) => {
          this.detailData = res.data

          this.dataList[0].value = this.detailData.hospital_name
          this.dataList[1].value = this.detailData.instrument_id
          this.dataList[2].value = this.detailData.platform_unique
          this.dataList[3].value = this.detailData.testing_unique
          this.dataList[4].value = this.detailData.batch
          this.dataList[5].value = this.detailData.hos_pos_name
          this.dataList[6].value = this.detailData.time_stamp

          // this.tableDataDity = res.data.calibration_abs_tar_data
          this.seriesData = res.data.line1_abs_data
          this.seriesDatatwo = res.data.line2_abs_data
          this.seriesDataX = res.data.calibration_abs_tar_data
          this.tableData = res.data.line1_abs_data.map((v, i) => {
            return {
              line1_abs_data: res.data?.line1_abs_data[i],
              line2_abs_data: res.data?.line2_abs_data[i],
              calibration_abs_tar_data: res.data?.calibration_abs_tar_data[i],
            }
          })
          this.echartsShow()
        })
    },
    echartsShow() {
      this.myChart = this.$echarts.init(document.getElementById('main'))
      //配置图表
      // var option = {
      //   grid: {
      //     top: '16%',
      //     left: '2%',
      //     right: '6%',
      //     bottom: '5%',
      //     containLabel: true,
      //   },
      //   xAxis: {
      //     type: 'category',
      //     name: '均值',
      //     data: this.seriesDataX,
      //     splitLine: {
      //       show: true,
      //     },
      //     boundaryGap: false,
      //     lineStyle: {
      //       color: 'rgb(215, 215, 215)',
      //       width: 1,
      //       type: 'solid',
      //     },
      //     axisLine: {
      //       show: true,
      //       lineStyle: {
      //         color: 'rgba(215, 215, 215, 1)',
      //         width: 1,
      //         type: 'solid',
      //       },
      //     },
      //   },
      //   yAxis: {
      //     type: 'value',
      //     name: '吸光度',
      //     splitNumber: 5,
      //     axisLabel: {
      //       formatter: '{value}',
      //     },

      //     axisLine: {
      //       show: true, //显示和隐藏轴线
      //       lineStyle: {
      //         //轴线样式设置
      //         color: 'rgba(215, 215, 215, 1)',
      //         width: 1,
      //         type: 'solid',
      //       },
      //     },
      //   },
      //   series: [
      //     {
      //       name: '吸光度一',
      //       data: this.seriesData,
      //       type: 'line',
      //       color: 'rgba(249, 158, 155, 1)',
      //     },
      //     {
      //       name: '吸光度二',
      //       data: this.seriesDatatwo,
      //       type: 'line',
      //       color: 'rgba(249, 158, 100, 1)',
      //     },
      //   ],
      // }
      let seriesData2 = [],
        seriesDatatwo2 = []
      for (let i in this.seriesDataX) {
        seriesData2.push([this.seriesDataX[i], this.seriesData[i]])
        seriesDatatwo2.push([this.seriesDataX[i], this.seriesDatatwo[i]])
      }
      var option = {
        grid: {
          top: '16%',
          left: '2%',
          right: '6%',
          bottom: '5%',
          containLabel: true,
        },
        tooltip: { trigger: 'axis' },
        legend: {
          data: ['吸光度1', '吸光度2',]
        },
        xAxis: {
          type: 'value',

          min: 0,
          max: 200,
          axisTick: { show: false }, // 隐藏刻度线
          splitLine: { show: false }, // 隐藏分割线
          axisLabel: { show: false }, // 隐藏刻度值
        },
        yAxis: [{ type: 'value' }],
        series: [
          {
            name: '吸光度1',
            type: 'line',
            smooth: true,
            data: seriesData2,
            markLine: {
              symbol: false, // 取消箭头
              silent: true, // 取消鼠标hover事件
              // label: {
              //   position: 'start', // 改变label位置
              //   formatter: obj => obj.value , // 格式化标签文本
              // },
              lineStyle: { color: '#ddd', type: 'solid' },
              data: this.seriesDataX.map((val) => {
                return {
                  xAxis: val,
                }
              }),
            },
            z: 10, // markLine应该有一个默认的z,但是不能修改，这里控制曲线z > markLine层级，保证markLine在曲线下方。
          },
          {
            name: '吸光度2',
            type: 'line',
            smooth: true,
            data: seriesDatatwo2,
            markLine: {
              symbol: false, // 取消箭头
              silent: true, // 取消鼠标hover事件
              label: {
                position: 'start', // 改变label位置
                formatter: (obj) => obj.value, // 格式化标签文本
              },
              lineStyle: { color: '#ddd', type: 'solid' },
              data: this.seriesDataX.map((val) => {
                return {
                  xAxis: val,
                }
              }),
            },
            z: 10, // markLine应该有一个默认的z,但是不能修改，这里控制曲线z > markLine层级，保证markLine在曲线下方。
          },
        ],
      }
      this.myChart.setOption(option)
    },
  },
}
</script>

<style lang="scss" scoped>
.melee {
  position: relative;
  left: px(1500);
  top: px(20);
  font-size: px(16);
  font-weight: bold;
  z-index: 1000;
}
.zc {
  color: #54b64a;
}
.yc {
  color: #f1734e;
}
.el-step__iconis-text {
  width: 8px;
  height: 8px;
  background: #e5e5e5;
}
.sheet {
  border-radius: px(5);
  padding: px(5);
  margin-bottom: px(10);
}
#main {
  width: 100%;
  height: px(484);
  background: #fff;
  border-radius: 5px;
  margin-top: px(-20);
}
</style>
